import Vue from "vue";
import Vuetify from "vuetify/lib";
import { colors } from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#d20f93',
        secondary: colors.grey.darken2,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.lightBlue.lighten4,
        footer: colors.lightBlue
      },
      dark: {
        primary: '#d20f93',
        background: colors.grey.darken4,
        footer: colors.purple.accent3
      }
    }
  }
});
